import {
  AuthorizationNotifier,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  LocalStorageBackend,
  RedirectRequestHandler,
  TokenRequest,
} from '@openid/appauth';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import getEnvironmentVariables from 'utils/EnvironmentVariables';
import NoHashQueryStringUtils from 'utils/NoHashQueryStringUtils';

const SilentRefresh: React.FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('error') === 'login_required') {
      const event = new CustomEvent('tokenArrived', {});
      //console.log('Token never arrived, SilentRefresh raising tokenArrived');
      window.parent.dispatchEvent(event);
    }

    const authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto(),
    );

    const notifier = new AuthorizationNotifier();
    authorizationHandler.setAuthorizationNotifier(notifier);
    const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

    notifier.setAuthorizationListener((request, response, error) => {
      if (response) {
        const environmentVariables = getEnvironmentVariables();
        let extras: any = {};
        if (request && request.internal) {
          extras.code_verifier = request.internal.code_verifier;
          extras.prompt = 'none';
        }

        const tokenRequest = new TokenRequest({
          client_id: getEnvironmentVariables().openIdClientId,
          redirect_uri: `${window.location.origin}/silent-refresh`,
          grant_type: 'authorization_code',
          code: response.code,
          extras,
        });

        AuthorizationServiceConfiguration.fetchFromIssuer(
          environmentVariables.openIdDomain,
          new FetchRequestor(),
        )
          .then(oResponse => {
            const configuration = oResponse;
            return tokenHandler.performTokenRequest(configuration, tokenRequest);           
          })
          .then(oResponse => {
            sessionStorage.setItem('isUserAuthenticated', 'true');
            const event = new CustomEvent('tokenArrived', { detail: oResponse });
            window.parent.dispatchEvent(event);
          });
      }
      if (error) {
        console.log('Something went wrong');
      }
    });

    authorizationHandler.completeAuthorizationRequestIfPossible();
  }, []);
  return (
    <>
      <h4>Connexion réussie. Bienvenue sur PETD!</h4>
    </>
  );
};

export default SilentRefresh;
