
export const HIGHLIGHT_BOX_DESCRIPTION =
  'Bienvenue sur PETD!';

export const ACCESS_BOX_MAIN_TITLE = 'Accéder au portail';
export const ACCESS_BOX_DESCRIPTION = `Vous souhaitez en savoir plus sur l'entreprise ou contacter nos équipes :`;
export const ACCESS_BOX_BUTTON_LABEL = `Accès au site Sygma BNP Paribas Personal Finance`;

export const LEGAL_MENTIONS_LABEL = 'Mentions Légales';
export const LINKEDIN_NEWS_LABEL = 'Retrouvez nos actualités sur';

export const CONNECTION_PANEL_DESC = 'Accédez à votre espace personnel et sécurisé.';
export const CONNECTION_PANEL_BUTTON_LABEL = 'Se connecter';
