import styled from 'styled-components';

export const StyledBackground = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url(homepage_background.jpg);
  background-repeat: no-repeat, no-repeat;
  background-size: cover;
`;

export const StyledMainSidePanel = styled.div`
  height: 100%;
  width: 50%;
  min-width: 100%;
  padding: 12rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  font-family: Dosis;
  text-align: center;
`;

export const StyledMainHighlightBox = styled.div`
  position: relative;
  height: 13rem;
  width: 45rem;
  border-radius: 0px 11rem;
  background-color: rgb(229, 247, 240);
  padding: 3rem 10rem 3rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1rem 1rem 0px;
`;

export const StyledHighlightBoxCornerCircles = styled.div`
  box-sizing: border-box;
  height: 12.7rem;
  width: 12.7rem;
  border: 1.2rem solid #00915A;
  border-radius: 100%;
  position: absolute;
`;

export const StyledHighlightBoxText = styled.span`
  color: #292c2e;
  font-size: 45px;
  font-weight: 700;
`;

export const StyledAccessBox = styled.div`
  top: 20rem;
  position: absolute;
  height: 10rem;
  width: 25rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.2rem 1rem 0px;
  padding: 20px;
  text-align: center;
  align-items: center;
`;

export const StyledAccessBoxMainTitle = styled.span`
  color: rgb(41, 44, 46);
    font-family: Dosis;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 2.6rem;
`;

export const StyledAccessBoxDescription = styled.span`
  color: #4b4f54;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 2.6rem;
  margin-bottom: 1.6rem;
`;

export const StyledAccessBoxButtonLabel = styled.span`
  color: #268038;
  font-family: 'Open Sans';
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 2.6rem;
  text-align: center;
  margin-left: 1.3rem;
`;

