import AuthorizationService from 'api/AuthorizationService';

const LoginButton: React.FC = () => {
  return (
    <button 
    style={{ margin: '1rem 0rem 0px',
      height: '45px',
      width: '60%',
      borderRadius: '15px',
      fontSize: '22px',
      fontWeight: '600',
      borderStyle: 'none',
      color: 'white',
      backgroundColor: '#00915a'}} 
    type="button" onClick={() => AuthorizationService.authorize('/callback')}>Se connecter
    </button>
  );
};

export default LoginButton;
