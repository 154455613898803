import React, { useEffect } from 'react';
// Bootstrap components call
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Stylesheets call
import './style/SHeader.css';
// Content to display and data call
import { content as CONTENT, content } from 'content/content';
// Interfaces call
import { HeaderProps } from 'interfaces/header';
import AuthorizationService from 'api/AuthorizationService';
import { useNavigate } from 'react-router-dom';
import getEnvironmentVariables from 'utils/EnvironmentVariables';

/**
 * Component to display the header and change the title depending the page the user is
 *
 * @param {string} pageTitle title to display on the header pass by the parent component
 * @returns {React.ReactElement} header with a title changing depending the page
 */
export const Header: React.FC<HeaderProps> = React.memo(({ pageTitle }) => {
  Header.displayName = 'Header';
  const authType = getEnvironmentVariables().authType;
  const authenticatedUser = sessionStorage.getItem('authenticatedUser');
  const navigate = useNavigate();
  if (authenticatedUser) {
    if (authType=="interne") {
      content.user = JSON.parse(authenticatedUser).sub
    }else if (authType=="externe") {
    content.user = JSON.parse(authenticatedUser).given_name + ' ' +
      JSON.parse(authenticatedUser).family_name;
    }
  };

  useEffect(() => {
  }, []);

  return (
    <React.Fragment>
      <Container fluid>
        <Row className="header">
          {/* Disconnect */}
          <Col
            lg="1"
            className="d-flex justify-content-center align-items-center"
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                width: 'fit-content',
              }}
              role="button"
              tabIndex={0}
              onClick={() => {
                AuthorizationService.disconnect(navigate);

              }}
              onKeyDown={() => {
                AuthorizationService.disconnect(navigate);
              }}>
              Déconnexion
            </div>
          </Col>
          {/* Name & logo part */}
          <Col
            lg="2"
            className="d-flex justify-content-center align-items-center"
          >
            <p>{CONTENT.app_title}</p>
          </Col>
          {/* Title part */}
          <Col
            lg="6"
            className="d-flex justify-content-center align-items-center"
          >
            <p>{pageTitle}</p>
            
          </Col>
          {/* User part */}
          <Col
            lg="3"
            className="d-flex justify-content-center align-items-center"
          >
            <div className="iconContainer d-flex justify-content-center align-items-center">
              <i className="bi bi-person"></i>
            </div>
            <p>{CONTENT.user}</p>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
});
