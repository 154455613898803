import React from "react";
//Stylesheet call
import "./style/SButtonList.css";
//Interfaces call
import { ButtonListProps } from "interfaces/button";

/**
 * Component to display an list of button
 *
 * @param {React.ReactNode} slotA button wanted to be displayed on the first slot
 * @param {React.ReactNode} slotB button wanted to be displayed on the second slot
 * @param {React.ReactNode} slotC button wanted to be displayed on the third slot
 * @param {React.ReactNode} slotD button wanted to be displayed on the fourth slot
 * @param {React.ReactNode} slotE button wanted to be displayed on the fifth slot
 * @param {React.ReactNode} slotF button wanted to be displayed on the sixth slot
 * @returns {React.ReactElement} six slot component with button loaded in depending the order we want to display them
 */
export const ButtonList: React.FC<ButtonListProps> = ({
  slotA,
  slotB,
  slotC,
  slotD,
  slotE,
  slotF,
}) => {
  ButtonList.displayName = "ButtonList";

  return (
    <React.Fragment>
      <div className="wide-container d-flex">
        <div className="btnSlot d-flex justify-content-center">{slotA}</div>
        <div className="btnSlot d-flex justify-content-center">{slotB}</div>
        <div className="btnSlot d-flex justify-content-center">{slotC}</div>
        <div className="btnSlot d-flex justify-content-center">{slotD}</div>
        <div className="btnSlot d-flex justify-content-center">{slotE}</div>
        <div className="btnSlot d-flex justify-content-center">{slotF}</div>
      </div>
    </React.Fragment>
  );
};
