import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//Components call
import { Header } from "components/common/Header";
import { ButtonComp } from "components/common/ButtonComp";
import { ButtonList } from "components/common/ButtonList";
import { fileInputCheck, inputCheck } from "utils/formValidation";
//Bootstrap components call
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";
//Utils call
import { prevPage } from "utils/utils";
//Content call
import { content as CONTENT } from "content/content";
//Api call
import { apiClient } from "api";


/**
 * Component to make a request
 *
 * @returns {React.ReactElement} page returning a header, a form, and a button list to do multiple actions
 */
export const RequestCreation: React.FC = React.memo(() => {
  const location = useLocation();
  var error = location?.state?.error;
  RequestCreation.displayName = "RequestCreation";
  const navigate = useNavigate();

  const [libelle, setLibelle] = useState("");
  const [comment, setComment] = useState("");
  const [file, setFile] = useState<File>();

  const isInputLibelleValid: boolean = inputCheck(libelle);
  const isInputCommentValid: boolean = inputCheck(comment);
  const isInputFileValid: boolean = fileInputCheck(file);

  const isFormValid =
    isInputLibelleValid && isInputCommentValid && isInputFileValid;

  const constructByteArray = async (): Promise<number[]> => {
    if (file) {
      const bufferArray1 = await file.arrayBuffer();
      let ui8 = new Uint8Array(bufferArray1);
      let rawData = [...ui8];
      return rawData;
    }
    return [];
  };

  const buildUpload = async (): Promise<any> => {
    return {
      requestComment: comment,
      requestFile: await constructByteArray(),
      requestFilename: file!.name,
      requestLabel: libelle,
      requestUserId: "batch" //TODO: replace after implementation of ping identity
    };
  };

  const sendCreateRequest: () => void = async () => {
    const petdCreateRequestData = await buildUpload();

    try {
      var res;
      await apiClient().petd.postCreateRequest(
        petdCreateRequestData
      ).then((data)=> res = data
      ).catch((err)=> navigate("/list", { state: { error: err.res.response.data[0].errorDescription } }));

      if (res.status == 200) {
        navigate("/list", { state: { display: 'Votre demande est en cours de traitement' } });
      } else {
        if (res.response.status!=200) {
          navigate("/create", { state: { error: 'Erreur: ' + res.response.data[0].errorDescription } });
        }
      }
      
    } catch (err) {
        console.log(err);
        navigate("/list", { state: { error: "Une erreur s'est produite. Veuillez réessayer." } });
    }
  };

  useEffect(() => {
    window.history.replaceState({state: null}, document.title );
   }, [])

  return (
    <React.Fragment>
      <Header pageTitle={CONTENT.header.req_create} />
      {error!=null ? (
        <div className='w-75 mx-auto pt-3'>
          <div className='border border-danger flex-wrap text-center'>
            <p className="mb-1 mt-1">{error}</p>
          </div>
        </div>
      ) : null}

      <Form className='w-75 mx-auto'>
        <Form.Group
          className='d-flex justify-content-between align-items-center my-4'
          controlId='formPlainTextRequestLabel'
        >
          <Form.Label className='m-0'>
            {CONTENT.req_create.req_label}
          </Form.Label>
          <InputGroup
            className='w-75'
            hasValidation
          >
            <Form.Control
              type='text'
              placeholder='Libellé'
              maxLength={45}
              value={libelle}
              onChange={(e) => {
                setLibelle(e.target.value as string);
              }}
              isInvalid={!isInputLibelleValid}
            />
            {!isInputLibelleValid && (
              <Form.Control.Feedback type='invalid'>
                {CONTENT.error_message.characters}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>

        <Form.Group
          className='d-flex justify-content-between align-items-center my-4'
          controlId='formPlainTextRequestFile'
        >
          <Form.Label>{CONTENT.req_create.file}</Form.Label>
          <InputGroup
            className='w-75'
            hasValidation
          >
            <Form.Control
              className='w-75'
              type='file'
              accept='.csv'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFile(event.target.files![0]);
              }}
              isInvalid={!isInputFileValid}
            />
            {!isInputFileValid && (
              <Form.Control.Feedback type='invalid'>
                {CONTENT.error_message.file_type}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>

        <Form.Group
          className='d-flex justify-content-between align-items-center my-4'
          controlId='formPlainTextRequestComment'
        >
          <Form.Label>{CONTENT.req_create.comment}</Form.Label>
          <InputGroup
            className='w-75'
            hasValidation
          >
            <Form.Control
              type='text'
              placeholder='Commentaire'
              maxLength={100}
              onChange={(e) => {
                setComment(e.target.value as string);
              }}
              value={comment}
              isInvalid={!isInputCommentValid}
            />
            {!isInputCommentValid && (
              <Form.Control.Feedback type='invalid'>
                {CONTENT.error_message.characters}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
      </Form>

      <ButtonList
        slotA={
          <ButtonComp
            btnLabel={CONTENT.buttons.previous}
            variant='link'
            size='lg'
            btnFunc={prevPage}
          />
        }
        slotF={
          <ButtonComp
            btnLabel={CONTENT.buttons.ok}
            variant='primary'
            size='lg'
            btnFunc={() => sendCreateRequest()}
            btnState={!isFormValid}
          />
        }
      />
    </React.Fragment>
  );
});
