import { Pagination } from "react-bootstrap";

/**
 * Method displaying pagination
 * @returns pagination items
 */
export function MyPagination({ total, current, onChangePage, pageSize }) {
  let items = [];

  //Determine number of pages according to number of values
  const pagesCount = Math.ceil(total / pageSize);

  if (current > 1) {
    items.push(
      <Pagination.Prev
        key='prev'
        onClick={() => {
          onChangePage(current - 1);
        }}
      />
    );
  }

  for (let page = 1; page <= pagesCount; page++) {
    items.push(
      <Pagination.Item
        key={page}
        data-page={page}
        active={page === current}
        onClick={() => {
          onChangePage(page);
        }}
      >
        {page}
      </Pagination.Item>
    );
  }

  if (current < total) {
    items.push(
      <Pagination.Next
        key='next'
        onClick={() => {
          onChangePage(current + 1);
        }}
      />
    );
  }

  return <Pagination>{items}</Pagination>;
}
