import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//Components call
import { Header } from "components/common/Header";
import { ButtonComp } from "components/common/ButtonComp";
import { inputCheck } from "utils/formValidation";
//Content to display and data call
import { content as CONTENT } from "content/content";
import { request_pending } from "data/mockData";
import { ButtonList } from "components/common/ButtonList";
import { prevPage } from "utils/utils";
import { store } from "store/store";
import { apiClient } from "api";
import { ReadDetail, Extract } from "interfaces/readDetail";
import { FcCancel } from 'react-icons/fc';
//Bootstrap components call
import Form from "react-bootstrap/Form";
import { InputGroup } from "react-bootstrap";

/**
 * Component display request informations
 */
export const RequestPending: React.FC = React.memo(() => {

  RequestPending.displayName = "RequestPending";

  var location = useLocation();
  var display = location?.state?.display;
  var error = location?.state?.error;

  const [libelle, setLibelle] = useState("");
  const [comment, setComment] = useState("");

  const isInputLibelleValid: boolean = inputCheck(libelle);
  const isInputCommentValid: boolean = inputCheck(comment);

  const navigate = useNavigate();
  const {
    requestID,
  } = useContext(store);
  const [readDetailRequest, setReadDetailRequest] = useState<ReadDetail>();

  const [solarCompleted, setSolarCompleted] = useState(true);
  const [solarNotprocessed, setSolarNotprocessed] = useState(true);

  const [xeroxCompleted, setXeroxCompleted] = useState(true);
  const [xeroxNotprocessed, setXeroxNotprocessed] = useState(true);

  const [worldlineCompleted, setWorldlineCompleted] = useState(false);
  const [worldlineNotprocessed, setWorldlineNotprocessed] = useState(false);
  const [worldlineExists, setWorldlineExists] = useState(false);

  var request;
  const getReadDetailRequest = async () => {

    if (requestID) {
      request = requestID;
      sessionStorage.setItem("currentRequest", requestID!);
    } else {
      request = sessionStorage.getItem("currentRequest");
    }
    var { data } = await apiClient().petd.getReadDetailRequest(request);
    const readDetailRequest = /^true$/i.test((window as unknown as WindowWithEnv)?._env_?.useMock || "false")
      ? request_pending
      : data;
    setReadDetailRequest(readDetailRequest);

    readDetailRequest?.extracts.forEach((extract: Extract) => {
      if (extract.documentProviderId === "SOLAR" && extract.extractStatus !== "COMPLETED") {
        setSolarCompleted(false);
      }
      if (extract.documentProviderId === "SOLAR" && extract.extractStatus !== "NOT_PROCESSED") {
        setSolarNotprocessed(false);
      }
      if (extract.documentProviderId === "XEROX" && extract.extractStatus !== "COMPLETED") {
        setXeroxCompleted(false);
      }
      if (extract.documentProviderId === "XEROX" && extract.extractStatus !== "NOT_PROCESSED") {
        setXeroxNotprocessed(false);
      }

      if (extract.documentProviderId === "WORLDLINE") {
        setWorldlineExists(true);
        setWorldlineCompleted(true);
        setWorldlineNotprocessed(true);

        if (extract.extractStatus !== "COMPLETED") {
          setWorldlineCompleted(false);
        }
        if (extract.extractStatus !== "NOT_PROCESSED") {
          setWorldlineNotprocessed(false);
        }
      }
    });
  };

  const sendDeleteRequest: () => void = async () => {
    try {
      const response = await apiClient().petd.deleteRequest(
        sessionStorage.getItem("currentRequest")
      );
      if (response.statusCode === 200) {
        navigate("/list", { state: { display: 'Demande supprimée avec succès!' } });
      } else {
        navigate("/reqPending", { state: { error: 'Demande de suppression non aboutit. Veuillez réessayer.' } });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getFileRequest: () => void = async () => {
    try {
      const response = await apiClient().petd.getRetrieveFileRequest(
        sessionStorage.getItem("currentRequest")
      );
      if (response.statusCode === 200) {
        const base64str = response.data.requestFile;
        const blob = await fetch(`data:text/csv;charset=utf-8;base64,${base64str}`).then(res => res.blob());
        let url = URL.createObjectURL(blob);

        // create <a> tag dinamically
        let fileLink = document.createElement('a');
        fileLink.href = url;

        // it forces the name of the downloaded file
        fileLink.download = response.data.requestFilename;

        // triggers the click event
        fileLink.click();
      } else {
        navigate("/reqPending", { state: { error: "Une erreur s'est produite. Veuillez réessayer." } });
      }
    } catch (err) {
      console.log(err);
      navigate("/reqPending", { state: { error: "Une erreur s'est produite. Veuillez réessayer." } });
    }
  };

  const getReportRequest: () => void = async () => {
    try {
      var res;
      await apiClient().petd.getRetrieveReportRequest(
        sessionStorage.getItem("currentRequest")
      ).then((data)=> res = data
      ).catch((err)=> navigate("/list", { state: { error: err.res.response.data[0].errorDescription } }));

      if (res.status === 200) {
        // const base64str = response.data.requestFile;
        // const blob = await fetch(`data:text/csv;charset=utf-8;base64,${base64str}`).then(res => res.blob());
        // let url = URL.createObjectURL(blob);
        let url = res.data.url.url;

        // create <a> tag dynamically
        let fileLink = document.createElement('a');
        fileLink.href = url;

        // it forces the name of the downloaded file
        fileLink.download = res.data.requestFilename+'.csv';

        // triggers the click event
        fileLink.click();
      } else {
        if (res.response.status!=200) {
          navigate("/reqPending", { state: { error: 'Erreur: ' + res.response.data[0].errorDescription } });
        }
      }
    } catch (err) {
      console.log(err);
      navigate("/reqPending", { state: { error: "Une erreur s'est produite. Veuillez réessayer." } });
    }
  };

  //request status
  let complete = readDetailRequest?.requestStatus === "COMPLETED";
  let published = readDetailRequest?.requestStatus === "PUBLISHED";
  let inprogress = readDetailRequest?.requestStatus === "IN PROGRESS" || (!worldlineNotprocessed || !xeroxNotprocessed || !solarNotprocessed);

  const buildUploadStatus = async (): Promise<any> => {
    var newStatus = "COMPLETED";
    if (complete) {
      newStatus = "PUBLISHED";
    }

    return {
      requestLabel: readDetailRequest?.requestLabel,
      requestComment: readDetailRequest?.requestComment,
      requestStatus: newStatus,
      requestUserId: 'batch' //TODO: test return X-sub
    };
  };

  const buildUpload = async (): Promise<any> => {
    var newlabel = readDetailRequest?.requestLabel;
    var newcomment = readDetailRequest?.requestComment;

    if (isInputLibelleValid) {
      newlabel = libelle
    };

    if (isInputCommentValid) {
      newcomment = comment
    };

    var editData;

    if (isInputLibelleValid || isInputCommentValid) {
      editData={
        requestLabel: newlabel,
        requestComment: newcomment,
        requestStatus: readDetailRequest?.requestStatus,
        requestUserId: 'batch' //TODO: test return X-sub
      };
    }else{
      editData = null;
    }

    return editData;
  };

  const sendUpdateRequest: () => void = async () => {
    const petdUpdateRequestData = await buildUpload();

    try {
      if (petdUpdateRequestData!=null) {
        var res;
        await apiClient().petd.postUpdateRequest(
          sessionStorage.getItem("currentRequest"), petdUpdateRequestData
        ).then((data)=> res = data
        ).catch((err)=> navigate("/reqPending", { state: { error: err.res.response.data[0].errorDescription } }));

        if (res.status == 200) {
          navigate("/reqPending", { state: { display: 'Modifications enregistrée avec succès!' } });
          window.location.reload();
        } else{
          if (res.response.status!=200) {
            navigate("/reqPending", { state: { error: 'Erreur: ' + res.response.data[0].errorDescription } });
          }
        }
        
      }else{
        navigate("/reqPending", { state: { error: "Veuillez remplir au moins un champs de modification." } });
      }
    } catch (err) {
      console.log(err);
      navigate("/reqPending", { state: { error: "Demande de modification non aboutit. Veuillez réessayer." } });
    }
  };

  const sendUpdateStatusRequest: () => void = async () => {
    const petdUpdateRequestData = await buildUploadStatus();

    try {
      var res;
      await apiClient().petd.postUpdateRequest(
        sessionStorage.getItem("currentRequest"), petdUpdateRequestData
      ).then((data)=> res = data
      ).catch((err)=> navigate("/reqPending", { state: { error: err.res.response.data[0].errorDescription } }));

      if (res.status === 200) {
        navigate("/reqPending", { state: { display: 'Statut modifié avec succès!' } });
        window.location.reload();
      } else {
        if (res.response.status!=200) {
          navigate("/reqPending", { state: { error: 'Erreur: ' + res.response.data[0].errorDescription } });
        }
      }
    } catch (err) {
      console.log(err);
      navigate("/reqPending", { state: { error: "Une erreur s'est produite dans le changement du status." } });
    }
  };

  const [show, setShow] = useState(false)

  useEffect(() => {
    getReadDetailRequest();
    setTimeout(() => setShow(true), 1300);
  }, [])

  return (
    <React.Fragment>
      <Header pageTitle={CONTENT.header.req_pending + sessionStorage.getItem("currentRequest")!} />
      {display != null ? (

        <div className='w-75 mx-auto pt-3'>
          <div className='border border-success flex-wrap text-center'>
            <p className="mb-1 mt-1">{display}</p>
          </div>
        </div>
      ) : null}
      {error != null ? (
        <div className='w-75 mx-auto pt-3'>
          <div className='border border-danger flex-wrap text-center'>
            <p className="mb-1 mt-1">{error}</p>
          </div>
        </div>
      ) : null}
      <div className='w-75 mx-auto pt-3'>
        <div className='row border-bottom border-2'>
          <div className='col mt-3'>
            {/* Left side informations,date,destintaires,satut,commentaires */}

            <div className='row'>
              <div className='d-flex my-2'>
                <p className='fw-bold me-3'>{CONTENT.req_list.date}:</p>
                <p>{readDetailRequest?.requestDate}</p>
              </div>
              <div className='d-flex my-2'>
                <p className='fw-bold me-3'>{CONTENT.req_list.dest}:</p>
                <p>{readDetailRequest?.documentsRecipient}</p>
              </div>
              <div className='d-flex my-2'>
                <p className='fw-bold me-3'>{CONTENT.req_list.status}:</p>
                <p>{readDetailRequest?.requestStatus}</p>
              </div>

              <Form.Group
                className='my-2'
                controlId='formPlainTextRequestComment'
              >
                <div className='d-flex my-2'>
                  <p className='fw-bold me-3'>{CONTENT.req_pending.comment}:</p>
                  <div className='w-100 d-flex flex-wrap'>
                    <p>{readDetailRequest?.requestComment}</p>
                  </div>
                </div>
                {sessionStorage.getItem('authtype') == "interne" ? (
                  <InputGroup
                    className='w-75'
                    hasValidation
                  >
                    <Form.Control className='w-100 d-flex flex-wrap form-input'
                      type='text'
                      placeholder='Nouveau Commentaire'
                      maxLength={100}
                      onChange={(e) => {
                        setComment(e.target.value as string);
                      }}
                      value={comment}
                      isInvalid={!isInputCommentValid}
                    />
                    {!isInputCommentValid && (
                      <Form.Control.Feedback type='invalid'>
                        {CONTENT.error_message.characters}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>) : null}
              </Form.Group>
            </div>

          </div>
          {/* Right informations,type,label,nb_dir*/}
          <div className='col mt-3 d-flex justify-content-end align-items-start'>
            <div className='row '>
              <div className='d-flex my-2'>
                <p className='fw-bold me-3'>{CONTENT.req_list.type}:</p>
                <p>{readDetailRequest?.requestType}</p>
              </div>


              <Form.Group
                className='my-2'
                controlId='formPlainTextRequestLabel'
              >
                <div className='d-flex my-2'>
                  <p className='fw-bold me-3'>{CONTENT.req_list.label}:</p>
                  <p>{readDetailRequest?.requestLabel}</p></div>

                {sessionStorage.getItem('authtype') == "interne" ? (
                  <InputGroup
                    className='w-75'
                    hasValidation
                  >
                    <Form.Control className='w-100 d-flex flex-wrap'
                      type='text'
                      placeholder='Nouveau Libellé'
                      maxLength={45}
                      value={libelle}
                      onChange={(e) => {
                        setLibelle(e.target.value as string);
                      }}
                      isInvalid={!isInputLibelleValid}
                    />
                    {!isInputLibelleValid && (
                      <Form.Control.Feedback type='invalid'>
                        {CONTENT.error_message.characters}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>) : null}
              </Form.Group>
              <div className='d-flex my-2'>
                <p className='fw-bold me-3'>{CONTENT.req_list.nb_dir}:</p>
                <p>{readDetailRequest?.nbContract}</p>
              </div>
              <div className='my-2'>
                <button className='ask-link btn btn-light' onClick={getFileRequest}>
                  {CONTENT.req_pending.link.request_file}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* loading status line above request informations */}
        <div className='w-80 mt-4 d-flex justify-content-around'>
          <div className=' d-flex me-5'>
            <p className='my-3 me-3'>
              {CONTENT.req_pending.loading_status.solar}
            </p>
            {solarNotprocessed ? (
              null
            ) : solarCompleted ? (
              <>
                {/* finish status display green mark */}
                <span className='checkmark my-2 me-5'></span>
              </>
            ) : ( //statut "en cours" affiche sablier
              <i className='bi bi-hourglass-split mt-2'></i>
            )
            }
          </div>
          <div className=' d-flex ms-5 me-5'>
            <p className='my-3 me-3 '>
              {CONTENT.req_pending.loading_status.xerox}
            </p>
            {xeroxNotprocessed ? (
              null
            ) : xeroxCompleted ?
              (<>
                {/* finish status display green mark */}
                <span className='checkmark my-2 me-5'></span>
              </>)
              : ( //statut "en cours" affiche sablier
                <i className='bi bi-hourglass-split mt-2'></i>
              )
            }
          </div>
          <div className=' d-flex ms-5 me-5'>
            <p className='my-3 me-3 '>
              {CONTENT.req_pending.loading_status.worldline}
            </p>
            {
              worldlineNotprocessed ? (
                worldlineExists ? (
                  null
                ) :
                  (<>
                    <h2><FcCancel /></h2>
                  </>)
              ) : worldlineCompleted ? (
                <>
                  {/* finish status display green mark */}
                  <span className='checkmark my-2 me-5'></span>
                </>
              ) : ( //statut "en cours" affiche sablier
                worldlineExists ? (
                  <i className='bi bi-hourglass-split mt-2'></i>
                ) :
                  (<>
                    <h2><FcCancel /></h2>
                  </>)
              )
            }
          </div>
          {complete || published ? (
            <button className='ask-link btn btn-light my-3' onClick={getReportRequest}>
              {CONTENT.req_pending.link.report}

            </button>
          ) : null}
        </div>
      </div>

      {/* Display buttons according to status */}
      {complete ? (
        <ButtonList
          slotA={
            <ButtonComp
              btnLabel={CONTENT.buttons.previous}
              variant='link'
              size='lg'
              btnFunc={prevPage}
            />
          }
          slotC={sessionStorage.getItem('authtype') == "interne" ? (
            <Link to={'/extraction/'} >
              <ButtonComp
                btnLabel={CONTENT.buttons.archives}
                variant='link'
                size='lg'
              />
            </Link>
          ) : null}

          slotE={sessionStorage.getItem('authtype') == "interne" ? (
            <ButtonComp
              btnLabel='Modifier'
              variant='primary'
              size='lg'
              btnFunc={() => sendUpdateRequest()}
            />
          ) : null}

          slotF={sessionStorage.getItem('authtype') == "interne" ? (
            <ButtonComp
              btnLabel='Publier'
              variant='primary'
              size='lg'
              btnFunc={() => sendUpdateStatusRequest()}
            />
          ) : null}
        />
      ) : published ? (
        <ButtonList
          slotA={
            <ButtonComp
              btnLabel={CONTENT.buttons.previous}
              variant='link'
              size='lg'
              btnFunc={prevPage}
            />
          }
          slotC={
            <Link to={'/extraction/'} >
              <ButtonComp
                btnLabel={CONTENT.buttons.archives}
                variant='link'
                size='lg'
              />
            </Link>
          }

          slotE={sessionStorage.getItem('authtype') == "interne" ? (
            <ButtonComp
              btnLabel='Modifier'
              variant='primary'
              size='lg'
              btnFunc={() => sendUpdateRequest()}
            />
          ) : null}
          slotF={sessionStorage.getItem('authtype') == "interne" ? (
            <ButtonComp
              btnLabel='Annuler Publication'
              variant='primary'
              size='lg'
              btnFunc={() => sendUpdateStatusRequest()}
            />
          ) : null}

        />
      ) : inprogress ? (
        <ButtonList
          slotA={
            <ButtonComp
              btnLabel={CONTENT.buttons.previous}
              variant='link'
              size='lg'
              btnFunc={prevPage}
            />
          }
          slotE={sessionStorage.getItem('authtype') == "interne" ? (
            <ButtonComp
              btnLabel='Modifier'
              variant='primary'
              size='lg'
              btnFunc={() => sendUpdateRequest()}
            />
          ) : null}
        />
      ) : (
        <ButtonList
          slotA={
            <ButtonComp
              btnLabel={CONTENT.buttons.previous}
              variant='link'
              size='lg'
              btnFunc={prevPage}
            />
          }
          slotE={sessionStorage.getItem('authtype') == "interne" ? (
            <ButtonComp
              btnLabel='Modifier'
              variant='primary'
              size='lg'
              btnFunc={() => sendUpdateRequest()}
            />
          ) : null}
          slotF={sessionStorage.getItem('authtype') == "interne" ? (
            <ButtonComp
              btnLabel='Annuler'
              variant='primary'
              size='lg'
              btnFunc={() => sendDeleteRequest()}
            />
          ) : null}
        />
      )}
    </React.Fragment>
  );
});
