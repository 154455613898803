export const content = {
  app_title: "PETD",
  user: "Jean DUPONT",
  header: {
    req_list: "Demandes d'extraction",
    req_create: "Nouvelle demande",
    req_pending: "Demande: ",
    req_extraction: "Demande: ",
  },
  buttons: {
    create: "Nouvelle demande",
    ok: "Valider",
    pagination: "< 1 ... 5 ... 10 >",
    previous: "Précédent",
    archives: "Archives",
  },
  req_list: {
    label: "Libellé",
    type: "Type",
    status: "Statut",
    date: "Date",
    nb_dir: "Nb dossiers",
    dest: "Destinataire",
  },
  req_create: {
    req_label: "Libellé de la demande:",
    file: "Fichier",
    comment: "Commentaire :",
  },
  req_pending: {
    comment: "Commentaires",
    loading_status: {
      solar: "Ged SOLAR",
      xerox: "Ged XEROX",
      worldline: "Ged WORLDLINE",
    },
    link: {
      request_file: "fichier Demande",
      report: "Compte rendu",
    },
  },
  table_extraction: {
    name: "Ged",
    batch: "Lot",
    size: "Taille",
    download_request:"Demande de Téléchargement",
    download: "Téléchargement",
  },

  error_message: {
    characters:
      "Veuillez compléter les champs et utiliser des caractères valides",
    file_type:
      "Veuillez télécharger un fichier conforme au type demandé (.csv)",
  },
};
