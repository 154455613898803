import axios, { AxiosError, AxiosInstance } from "axios";
import { setupCache } from "axios-cache-interceptor";

/**
 * @function axiosInstance
 * @returns {AxiosInstance}
 * this function creates a new instance of axios
 * purpose: to have a centralized, customized configuration in one place
 */
export function axiosInstance(): AxiosInstance {
  /**
   * @constant cache
   * @type {setupCache}
   * here we instantiate the axios-cache-adapter on its own using the setupCache() method
   * we then attach the adapter manually to an instance of axios
   */
  // const cache = setupCache({
  //   maxAge: 60 * 60 * 1000,
  //   exclude: {
  //     query: false,
  //     methods: ["post", "patch", "put", "delete", "get"],
  //     paths: [],
  //   },
  // });

  /** the create property of axios receives a 
   * @param {AxiosRequestConfig<any> | undefined} configuration object, that in our case consists of:
    * @property {AxiosAdapter} adapter - used for cache-ing the results of the allowed requests
    * @property {string | undefined } baseURL - bse url to which we make the request
    * @property {AxiosRequestHeaders | undefined } headers - for qual/pprod/prod envs we need to pass the apimApiKey
    * @property {number | undefined } timeout - number that indicates the time in milliseconds for the request to be aborted
    * @property {string | undefined } timeoutErrorMessage - the customized error message in case of a timeout (on the error object, check for timeout like this: error.code === 'ECONNTIMEOUT')
   */
  const axiosBack = axios.create({
    baseURL: (window as unknown as WindowWithEnv)._env_.baseUrlApim,
    headers: { 
      authorization: `Bearer ${sessionStorage.getItem("accessToken")}`|| '' 
    },
    timeout: 30000,
    timeoutErrorMessage: 'ECONNTIMEOUT',
  });

  const cache = setupCache(axiosBack);

  /** We intercept the responses before they are handled by then or catch
   * Here, we just convert the error to the type AxiosError, but more logic can be added, if needed
   */
  axiosBack.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error: AxiosError) {
      return error;
    },
  );

  return axiosBack;
}
