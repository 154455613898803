import { createContext,  useState } from "react";

//Interface for type declaration of store's variables
interface AppContextType {
  //RequestList part
  documentsRecipient: string | undefined;
  setDocumentsRecipient: (newStatus: string | undefined) => void;
  requestDate: string | undefined;
  setRequestDate: (newStatus: string | undefined) => void;
  requestLabel: string | undefined;
  setRequestLabel: (
    newStatus: string | undefined
  ) => void;
  requestStatus: string | undefined;
  setRequestStatus: (
    newStatus: string | undefined
  ) => void;
  requestType: string | undefined;
  setRequestType: (
    newStatus: string | undefined
  ) => void;
  // ExtractionBatches
  requestID: string |undefined;
  setRequestID: (
    newStatus: string | undefined
  ) => void;
  documentProviderId: string |undefined;
  setDocumentProviderId: (
    newStatus: string | undefined
  ) => void;
  fileChunk: number;
  setFileChunk: (
    newStatus: number 
  ) => void;
}

//Initialisation of store and it's default values
const store = createContext<AppContextType>({
  //RequestList
  documentsRecipient: undefined,
  setDocumentsRecipient: () => null,
  requestDate: undefined,
  setRequestDate: () => null,
  requestLabel: undefined,
  setRequestLabel: () => null,
  requestStatus: undefined,
  setRequestStatus: () => null,
  requestType: undefined,
  setRequestType: () => null,
  // ExtractionBatches
  requestID: undefined,
  setRequestID: () => null,
  documentProviderId: undefined,
  setDocumentProviderId: () => null,
  fileChunk: 0,
  setFileChunk: () => null,
});

const { Provider } = store;

/**
 * Provider to fill and use some global variables
 *
 * @param {JSX.Element} children app being load in the provider so it is in it's scope
 * @returns {React.ReactElement} Provider to fill and use some global variables throught it's scope
 */
const AppProvider = ({ children }: { children: JSX.Element }) => {
  //RequestList states
  const [documentsRecipient, setDocumentsRecipient] = useState<string | undefined>(undefined);
  const [requestDate, setRequestDate] = useState<string | undefined>(undefined);
  const [requestLabel, setRequestLabel] = useState<string | undefined>(undefined);
  const [requestStatus, setRequestStatus] = useState<string | undefined>(undefined);
  const [requestType, setRequestType] = useState<string | undefined>(undefined);
  // ExtractionBatches states
  const [requestID, setRequestID] = useState<string | undefined>(undefined);
  const [documentProviderId, setDocumentProviderId] = useState<string | undefined>(undefined);
  const [fileChunk, setFileChunk] = useState<number>(0);

  return (
    <Provider
      value={{
        //RequestList export
        documentsRecipient,
        setDocumentsRecipient,
        requestDate,
        setRequestDate,
        requestLabel,
        setRequestLabel,
        requestStatus,
        setRequestStatus,
        requestType,
        setRequestType,
        // ExtractionBatches export
        requestID,
        setRequestID,
        documentProviderId,
        setDocumentProviderId,
        fileChunk,
        setFileChunk,
      }}
    >
      {children}
    </Provider>
  );
};

export { store, AppProvider };

