export const request_pending = {
    requestId: "RJS_NPL_20230606",
    requestType: "RJS_NPL",
    requestDate: "2023-06-06",
    requestLabel: "Cession 06/06/2023",
    documentsRecipient: "PF",
    requestStatus: "COMPLETED",
    nbContract: 7,
    extracts: [
      {
        documentProviderId: "SOLAR",
        chunkNumber: 1,
        extractStatus: "COMPLETED"
      },
      {
        documentProviderId: "XEROX",
        chunkNumber: 1,
        extractStatus: "COMPLETED"
      }
    ]
  };

export const extraction_batches = {
    requestId: "RJS_NPL_20230131",
    requestFileChunk: [
    {
      documentProviderId: "XEROX",
      fileChunk: 1,
      lastDownload: "1986-04-08 12:30",
      urls: [{
        url: "http://www.goolge.com",
        fileSize: 2.1,
      },
      {
        url: "http://www.goolge.com",
        fileSize: 2.5,
      },
      ],
    },
    {
      documentProviderId: "SOLAR",
      fileChunk: 1,
      lastDownload: "1986-04-08 12:30",
      urls: [{
        url: "http://www.goolge.com",
        fileSize: 1.1,
      },
    ],
    },
    {
      documentProviderId: "WORLDLINE",
      fileChunk: 1,
      urls: [{
        url: "http://www.goolge.com",
        fileSize: 3.1,
      },
    ],
    },
    {
      documentProviderId: "SOLAR",
      fileChunk: 2,
      urls: [{
        url: "http://www.goolge.com",
        fileSize: 2.2,
      },
    ],
    },
    {
      documentProviderId: "SOLAR",
      fileChunk: 3,
      urls: [{
        url: "http://www.goolge.com",
        fileSize: 2.7,
      },
    ],
    },

  ]
};

export const requests = [
  {
    requestLabel: "RJS_NPL_20220621",
    requestType: "RJS_NPL",
    requestStatus: "Initialisée",
    requestDate: "21/06/22",
    nbContract: 25,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220818",
    requestType: "RJS_NPL",
    requestStatus: "En cours",
    requestDate: "22/06/22",
    nbContract: 254,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220222",
    requestType: "RJS_NPL",
    requestStatus: "Terminée",
    requestDate: "23/06/22",
    nbContract: 125,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220901",
    requestType: "RJS_NPL",
    requestStatus: "Publiée",
    requestDate: "24/06/22",
    nbContract: 423,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220627",
    requestType: "RJS_NPL",
    requestStatus: "Annulée",
    requestDate: "21/06/22",
    nbContract: 87,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20221122",
    requestType: "RJS_NPL",
    requestStatus: "Fermée",
    requestDate: "24/06/22",
    nbContract: 17,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230101",
    requestType: "RJS_NPL",
    requestStatus: "Initialisée",
    requestDate: "21/06/22",
    nbContract: 25,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230210",
    requestType: "RJS_NPL",
    requestStatus: "En cours",
    requestDate: "22/06/22",
    nbContract: 254,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230315",
    requestType: "RJS_NPL",
    requestStatus: "Terminée",
    requestDate: "23/06/22",
    nbContract: 125,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230420",
    requestType: "RJS_NPL",
    requestStatus: "Publiée",
    requestDate: "24/06/22",
    nbContract: 423,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230530",
    requestType: "RJS_NPL",
    requestStatus: "Annulée",
    requestDate: "21/06/22",
    nbContract: 87,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230601",
    requestType: "RJS_NPL",
    requestStatus: "Fermée",
    requestDate: "24/06/22",
    nbContract: 17,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230712",
    requestType: "RJS_NPL",
    requestStatus: "Initialisée",
    requestDate: "21/06/22",
    nbContract: 25,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230824",
    requestType: "RJS_NPL",
    requestStatus: "En cours",
    requestDate: "22/06/22",
    nbContract: 254,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230929",
    requestType: "RJS_NPL",
    requestStatus: "Terminée",
    requestDate: "23/06/22",
    nbContract: 125,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220901",
    requestType: "RJS_NPL",
    requestStatus: "Publiée",
    requestDate: "24/06/22",
    nbContract: 423,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220627",
    requestType: "RJS_NPL",
    requestStatus: "Annulée",
    requestDate: "21/06/22",
    nbContract: 87,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20221122",
    requestType: "RJS_NPL",
    requestStatus: "Fermée",
    requestDate: "24/06/22",
    nbContract: 17,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220621",
    requestType: "RJS_NPL",
    requestStatus: "Initialisée",
    requestDate: "21/06/22",
    nbContract: 25,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220818",
    requestType: "RJS_NPL",
    requestStatus: "En cours",
    requestDate: "22/06/22",
    nbContract: 254,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220222",
    requestType: "RJS_NPL",
    requestStatus: "Terminée",
    requestDate: "23/06/22",
    nbContract: 125,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220901",
    requestType: "RJS_NPL",
    requestStatus: "Publiée",
    requestDate: "24/06/22",
    nbContract: 423,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220627",
    requestType: "RJS_NPL",
    requestStatus: "Annulée",
    requestDate: "21/06/22",
    nbContract: 87,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20221122",
    requestType: "RJS_NPL",
    requestStatus: "Fermée",
    requestDate: "24/06/22",
    nbContract: 17,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230101",
    requestType: "RJS_NPL",
    requestStatus: "Initialisée",
    requestDate: "21/06/22",
    nbContract: 25,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230210",
    requestType: "RJS_NPL",
    requestStatus: "En cours",
    requestDate: "22/06/22",
    nbContract: 254,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230315",
    requestType: "RJS_NPL",
    requestStatus: "Terminée",
    requestDate: "23/06/22",
    nbContract: 125,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230420",
    requestType: "RJS_NPL",
    requestStatus: "Publiée",
    requestDate: "24/06/22",
    nbContract: 423,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230530",
    requestType: "RJS_NPL",
    requestStatus: "Annulée",
    requestDate: "21/06/22",
    nbContract: 87,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230601",
    requestType: "RJS_NPL",
    requestStatus: "Fermée",
    requestDate: "24/06/22",
    nbContract: 17,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230712",
    requestType: "RJS_NPL",
    requestStatus: "Initialisée",
    requestDate: "21/06/22",
    nbContract: 25,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230824",
    requestType: "RJS_NPL",
    requestStatus: "En cours",
    requestDate: "22/06/22",
    nbContract: 254,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20230929",
    requestType: "RJS_NPL",
    requestStatus: "Terminée",
    requestDate: "23/06/22",
    nbContract: 125,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220901",
    requestType: "RJS_NPL",
    requestStatus: "Publiée",
    requestDate: "24/06/22",
    nbContract: 423,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20220627",
    requestType: "RJS_NPL",
    requestStatus: "Annulée",
    requestDate: "21/06/22",
    nbContract: 87,
    documentsRecipient: "Cabot",
  },
  {
    requestLabel: "RJS_NPL_20221122",
    requestType: "RJS_NPL",
    requestStatus: "Fermée",
    requestDate: "24/06/22",
    nbContract: 17,
    documentsRecipient: "Cabot",
  },
];
