import {
  AuthorizationNotifier,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  LocalStorageBackend,
  RedirectRequestHandler,
  TokenRequest,
} from '@openid/appauth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthorizationService from 'api/AuthorizationService';
import getEnvironmentVariables from 'utils/EnvironmentVariables';
import NoHashQueryStringUtils from 'utils/NoHashQueryStringUtils';
import { UserInfos, SubInfos } from 'types';

const Callback: React.FC = () => {

  const navigate = useNavigate();

  const handleFailedAuthentication = (error?: any) => {
    console.log(error);
    alert("Une erreur s'est produite lors de l'authentification, veuillez rééssayer.");
    navigate('/');
  };

  useEffect(() => {
    const authorizationHandler = new RedirectRequestHandler(
      new LocalStorageBackend(),
      new NoHashQueryStringUtils(),
      window.location,
      new DefaultCrypto(),
    );

    const user = sessionStorage.getItem('isUserAuthenticated');
    if (user == null) {
      window.location.replace("/login");
    } else {

      const notifier = new AuthorizationNotifier();
      authorizationHandler.setAuthorizationNotifier(notifier);
      const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());

      notifier.setAuthorizationListener((request, response, error) => {
        //console.log(request, response, error);
        if (response) {
          const envVariables = getEnvironmentVariables();
          let extras: any = {};
          if (request && request.internal) {
            extras.code_verifier = request.internal.code_verifier;
          }

          const tokenRequest = new TokenRequest({
            client_id: getEnvironmentVariables().openIdClientId,
            redirect_uri: `${window.location.origin}/callback`,
            grant_type: 'authorization_code',
            code: response.code,
            extras,
          });

          //console.log('getting config from issuer');
          AuthorizationServiceConfiguration.fetchFromIssuer(
            envVariables.openIdDomain,
            new FetchRequestor(),
          )
            .then(oResponse => {
              //console.log('perfoming token request');
              const configuration = oResponse;
              return tokenHandler.performTokenRequest(configuration, tokenRequest);
            })
            .then(oResponse => {
              //console.log('saving token and navigating');
              sessionStorage.setItem('isUserAuthenticated', 'true');
              AuthorizationService.saveTokenInfo(oResponse).then(
                () => {
                  let redirectUrl = '/home';
                  const session_redirect_url = sessionStorage.getItem('redirect_url');
                  if (session_redirect_url != null) {
                    redirectUrl = session_redirect_url;
                    sessionStorage.removeItem('redirect_url');
                  }
                  navigate(redirectUrl);
                },
                e => {
                  //console.log(e);
                  if (envVariables.authType=="interne") {
                    alert(
                      `Vous avez utilisé un login different ou vous n'avez pas les droits d'accès à ce site.`,
                    );
                  } else if (envVariables.authType=="externe") {
                    alert(
                      `Vous avez utilisé un login different de celui avec lequel vous etiez connecté, veuillez vous connecter avec l'utilisateur ${(
                        JSON.parse(
                          sessionStorage.getItem('authenticatedUser') || '{}',
                        ) as UserInfos
                      )?.uid
                      }`,
                    );
                  }else{
                    handleFailedAuthentication(error);
                  }
                  AuthorizationService.authorize('/callback');
                },
              );
            })
            .catch(e => {
              handleFailedAuthentication(e);
            });
        }
        if (error) {
          handleFailedAuthentication(error);
        }
      });
    };

    authorizationHandler
      .completeAuthorizationRequestIfPossible()
      .then(() => console.log('Completing authentication if possible'))
      .catch(error => {
        handleFailedAuthentication(error);
      });
  }, []);

  return <></>;
};

export default Callback;
