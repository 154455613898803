import {
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  BaseTokenRequestHandler,
  DefaultCrypto,
  FetchRequestor,
  LocalStorageBackend,
  RedirectRequestHandler,
  RevokeTokenRequest,
  TokenResponse,
} from '@openid/appauth';
import { AxiosRequestConfig } from 'axios';
import NoHashQueryStringUtils from 'utils/NoHashQueryStringUtils';
import { decodeToken } from 'react-jwt';
import getEnvironmentVariables from 'utils/EnvironmentVariables';
import DecodedAuthToken, {
  UserInfos, SubInfos
} from 'types';
import { NavigateFunction, useNavigate } from 'react-router';

/* This class handles OIDC access token requests
to use it, simply call authorize() to first login or addAccessTokenToHeaders()
when performing a request that needs an access token. */
export default class AuthorizationService {

  private static myInstance: AuthorizationService;

  private authType = getEnvironmentVariables().authType;

  private accessToken = '';

  private expiresAt = '';

  private isRefreshingToken = false;

  /* Class is setup as a singleton, this returns the existing instance
  or creates one if none exists, this allows us to store the token in memory
  as per security recommendations and in the scope of the service only */
  private static getInstance(): AuthorizationService {
    if (!AuthorizationService.myInstance) {
      AuthorizationService.myInstance = new AuthorizationService();
    }
    return AuthorizationService.myInstance;
  }

  private static authorizationHandler = new RedirectRequestHandler(
    new LocalStorageBackend(),
    new NoHashQueryStringUtils(),
    window.location,
    new DefaultCrypto(),
  );

  private static getAuthorizationServiceConfig =
    async (): Promise<AuthorizationServiceConfiguration> => {
      return AuthorizationServiceConfiguration.fetchFromIssuer(
        getEnvironmentVariables().openIdDomain,
        new FetchRequestor(),
      );
    };

  /* initializes the authentication process, you need to pass it the page
  you want to navigate to once the process is over. This works by sending
  a request to the authentication server which responds by redirecting you
  to their login page and back to the specified redirect_url once login
  is successful */
  static authorize = (redirectPage: string): void => {
    if (!sessionStorage.accessToken) {
      sessionStorage.setItem('isUserAuthenticated', 'false');
    }

    this.getAuthorizationServiceConfig().then(AuthConfig => {

      const authRequest = new AuthorizationRequest({
        client_id: getEnvironmentVariables().openIdClientId,
        redirect_uri: `${window.location.origin}${redirectPage}`,
        scope: 'openid profile',
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: '',
      });

      const extras: any = {};
      if (redirectPage === '/silent-refresh') {
        extras.prompt = 'none';
        extras.usernameEditable = false;
      } else {
        extras.prompt = 'login';
      }

      authRequest.extras = extras;

      this.authorizationHandler.performAuthorizationRequest(AuthConfig, authRequest);
    });
  };


  static checkEnv = (subEnvUrl: string): String => {
    try {
      const url = getEnvironmentVariables().openIdDomain;
      if (subEnvUrl === url) {
        return getEnvironmentVariables().env;
      };
      return "null";
    } catch (err) {
      throw Error(
        `Error occured when getting config from the /assets/config.json from the server. Reason: ${err}`,
      );
    }
  };

  static saveTokenInfo = async (tokenResponse: TokenResponse): Promise<void> => {
    const instance = AuthorizationService.getInstance();
    instance.accessToken = tokenResponse.accessToken;

    var resolve = false;
    const tokenInfos = decodeToken<DecodedAuthToken>(instance.accessToken);

    if (tokenInfos) {
      if (instance.authType == "interne") {
        sessionStorage.setItem('authtype', "interne");
        const currentUser = (
          JSON.parse(sessionStorage.getItem('authenticatedUser') || '{}') as SubInfos
        )?.sub;

        if (!currentUser || !tokenInfos.sub || currentUser === tokenInfos.sub) {
          try {

            // let subInfos = {} as SubInfos;
            // subInfos = { client_id: tokenInfos.client_id, sub: tokenInfos.sub };
            // sessionStorage.setItem('authenticatedUser', JSON.stringify(subInfos));

            if (tokenInfos.habilitations && tokenInfos.habilitations.length) {
              const habilitationInfos = tokenInfos.habilitations[0];

              if (habilitationInfos.roles) {
                const rolesInfos = habilitationInfos.roles[0];

                if (rolesInfos) {
                  var allow = false;
                  const env = this.checkEnv(tokenInfos.iss);

                  switch (env) {
                    case "dev":
                      if (rolesInfos.cn == "D-0000040615") {
                        allow = true;
                      }
                      break;

                    case "qual": 
                      if (rolesInfos.cn == "D-0000040615") {
                        allow = true;
                      }
                      break;
                    
                    case "pprod":
                      if (rolesInfos.cn == "D-0000040616") {
                        allow = true;
                      }
                      break;

                    case "prod":
                      if (rolesInfos.cn == "D-0000040614") {
                        allow = true;
                      }
                      break;
                  };

                  if (allow) {
                    resolve = true;
                    let subInfos = {} as SubInfos;
                    subInfos = { client_id: tokenInfos.client_id, sub: tokenInfos.sub };
                    sessionStorage.setItem('authenticatedUser', JSON.stringify(subInfos));

                  } else {
                    Promise.reject(new Error('incorrect role'));
                    this.disconnect(useNavigate);
                  }

                } else {
                  Promise.reject(new Error('role not found'));
                  this.disconnect(useNavigate);
                }
              } else {
                Promise.reject(new Error('no roles'));
                this.disconnect(useNavigate);
              }
            } else {
              Promise.reject(new Error('no habilitations'));
              this.disconnect(useNavigate);
            }
          } catch (error) {
            console.error(error);
            Promise.reject(new Error('an error has occured'));
            this.disconnect(useNavigate);
          }
        } else {
          Promise.reject(new Error('different_login'));
          this.disconnect(useNavigate);
        }

      } else if (instance.authType == "externe") {

        sessionStorage.setItem('authtype', "externe");
        const currentUser = (
          JSON.parse(sessionStorage.getItem('authenticatedUser') || '{}') as UserInfos
        )?.uid;

        if (!currentUser || !tokenInfos.sub || currentUser === tokenInfos.sub) {
          let userInfos = {} as UserInfos;
          userInfos = {
            uid: tokenInfos.uid, email: tokenInfos.email, family_name: tokenInfos.family_name,
            given_name: tokenInfos.given_name, phone_number: tokenInfos.phone_number, partner: tokenInfos.partner
          };
          try {
            resolve = true;
            sessionStorage.setItem('authenticatedUser', JSON.stringify(userInfos));
          } catch (error) {
            console.error(error);
          }
        }
      } else {
        Promise.reject(new Error('incorrect domain'));
        this.disconnect(useNavigate);
      }
    }
    if (resolve) {
      sessionStorage.setItem("accessToken", tokenResponse.accessToken);
      if (tokenResponse.expiresIn) {
        // console.log("calculating expiration");
        instance.expiresAt = (
          new Date().getTime() +
          // 900 = 1000 (convert to ms) * 0.90 (tolerance margin for token lifetime)
          tokenResponse.expiresIn * 900
        ).toString();
        sessionStorage.setItem("expiresAt", instance.expiresAt);
        // console.log(tokenResponse.expiresIn);
      } else {
        // TODO : Throw error and invalidate token
        instance.expiresAt = '0';
        sessionStorage.expiresAt = '0';
      }
      Promise.resolve();
    };
  };

  static delay = async (ms: number): Promise<void> =>
    new Promise(res => setTimeout(res, ms));

  /* Adds the access token to request headers.
  This function handles the silent token refresh process
  by opening a hidden iFrame that absobs the redirection */
  static addAccessTokenToHeaders = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosRequestConfig> => {
    if (sessionStorage.getItem('isUserAuthenticated') == 'true') {
      const instance = AuthorizationService.getInstance();

      if (AuthorizationService.tokenExpired()) {

        if (instance.isRefreshingToken) {
          await instance.silentlyRefreshToken();
          instance.isRefreshingToken = false;
        } else {
          await this.delay(10000);
        }
      }

      const result: AxiosRequestConfig = config;
      result.headers = config.headers ?? {};
      result.headers.authorization = `Bearer ${sessionStorage.getItem("accessToken")
        }`;

      return result;
    }

    return config;
  };

  static tokenExpired = (): boolean => {
    const instance = AuthorizationService.getInstance();
    if (Number(sessionStorage.getItem("expiresAt")) < new Date().getTime()) {
      instance.isRefreshingToken = true;
      return true;

    }
    //Token is valid;
    return false;
  };

  /* This works by opening a hidden iFrame on the /authorize route.
  The page then calls the authorize() function from this service with
  /silent-refresh as the redirect_uri. Once redirected to /silent-refresh,
  the page performs the token request and emits an event when the token 
  arrives. Upon recieving the event, this function stores the token and
  resolves, allowing addAccessTokenToHeaders() to continue its execution */
  private silentlyRefreshToken = (): Promise<void> => {
    return new Promise<void>((res, rej) => {

      const iframe = window.document.createElement('iframe');

      iframe.setAttribute('width', '0');
      iframe.setAttribute('height', '0');
      iframe.style.display = 'none';

      let iframeEventHandler: (e: MessageEvent) => void;

      const removeIframe = () => {
        if (window.document.body.contains(iframe)) {
          window.document.body.removeChild(iframe);
          window.removeEventListener('tokenArrived', iframeEventHandler, false);
        }
      };

      // eslint-disable-next-line func-names
      iframeEventHandler = async function (e: CustomEventInit<TokenResponse>) {
        // if the iframe sends back a result
        if (e.detail) {
          console.log("saving new token info")
          await AuthorizationService.saveTokenInfo(e.detail);

          // otherwise it means that the authentication session has expired
        } else {
          sessionStorage.setItem('redirect_url', window.location.pathname);
          AuthorizationService.authorize('/callback');
          rej(new Error('Authentication session has expired'));
        }
        window.removeEventListener('tokenArrived', iframeEventHandler, false);
        removeIframe();
        res();
      };

      //Adding token event listener
      window.addEventListener('tokenArrived', iframeEventHandler, false);
      window.document.body.appendChild(iframe);
      iframe.setAttribute('src', `${window.location.origin}/authorize`);

      // eslint-disable-next-line
      const timeoutSetTimeoutId = setTimeout(() => {
        window.removeEventListener('tokenArrived', iframeEventHandler, false);
        res();
        removeIframe();
      }, 10000);
    });
  };

  static revokeToken = (): void => {
    this.getAuthorizationServiceConfig().then(authConfig => {
      const enValues = getEnvironmentVariables();
      const revokeTokenRequest = new RevokeTokenRequest({
        token: AuthorizationService.getInstance().accessToken,
        client_id: enValues.openIdClientId,
      });
      //Token revoked
      const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor());
      tokenHandler.performRevokeTokenRequest(authConfig, revokeTokenRequest).then(() => {
      });
    });
  };

  // static getHeadersRequest = async (): Promise<AxiosResponse<any>> => {
  //   const config: AxiosRequestConfig = { headers: {} };
  //   const headersWithToken = await this.addAccessTokenToHeaders(config);
  //   // var path = '';
  //   // if (getEnvironmentVariables().authType=="interne") {
  //   //   path = "/userinfo";
  //   // } else {
  //   //   path = "/idp/userinfo.openid";
  //   // }
  //   return await axios.get(
  //     `${getEnvironmentVariables().openIdDomain}`,
  //     headersWithToken,
  //   );
  // };

  static disconnect = (navigate: NavigateFunction): void => {
    const envVariables = sessionStorage.getItem('envVariables');
    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem('envVariables', envVariables || '');
    navigate('/');
  };
}
