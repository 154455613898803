import React, { useContext, useEffect, useMemo, useState } from "react";
//Components call
import { Header } from "components/common/Header";
//Bootstrap components call
import { Table } from "react-bootstrap";
//Content to display and data call
import { content as CONTENT } from "content/content";
import { ButtonComp } from "components/common/ButtonComp";
import { ButtonList } from "components/common/ButtonList";
import { prevPage } from "utils/utils";
//Imports for backend call
import { apiClient } from "api/index";
import { ReadArchive, RequestFileChunk, Urls } from "interfaces/readArchive";
import { store } from "store/store";
import { ReadDetail } from "interfaces/readDetail";
import { request_pending } from "data/mockData";
import { useNavigate } from "react-router-dom";

/**
 * Display a table of loaded GED
 *
 * @returns {React.ReactElement} a page table displaying the differents loaded GED
 */
export const ExtractionBatches: React.FC = React.memo(() => {
  ExtractionBatches.displayName = "ExtractionBatches";
  const [readDetailRequest, setReadDetailRequest] = useState<ReadDetail>();
  const {
    requestID = sessionStorage.getItem("currentRequest"),
  } = useContext(store);

  const [readArchiveResults, setReadArchiveResults] = useState<Partial<ReadArchive>>({});
  const navigate = useNavigate();

  const getReadDetailRequest = async () => {
    var { data } = await apiClient().petd.getReadDetailRequest(requestID);
    const readDetailRequest = /^true$/i.test((window as unknown as WindowWithEnv)?._env_?.useMock || "false")
      ? request_pending
      : data;
    setReadDetailRequest(readDetailRequest);
  };

  //récupérer les lots
  const sendReadRequestArchive = async () => {
    //readRequest
    getReadDetailRequest();
    const { data } = await apiClient().petd.getReadRequestArchive(requestID);
    const results = data;
    setReadArchiveResults(results);
  };

  //récupérer les urls de téléchargement
  const downloadArchiveRequest = (documentProviderId: string, fileChunk: number): void => {

    const downloadRequestArchiveIn = {
      documentProviderId: documentProviderId,
      fileChunk: fileChunk
    };

    apiClient().petd.getDownloadRequestArchive(
      requestID, downloadRequestArchiveIn
    )
    .then(({ data }) => {
      let object = readArchiveResults.requestFileChunk;
      object?.forEach(ext => {
        if (ext.documentProviderId == documentProviderId && ext.fileChunk == fileChunk) {
          ext.urls = data.urls;
          timout(15000).then(() => {
            ext.urls = [];
            setReadArchiveResults(prevState => {
              return ({
                ...prevState,
                requestFileChunk: object
              });
            });
          });
        } else {
          ext.urls = [];
        };
      });
      setReadArchiveResults(prevState => {
        return ({
          ...prevState,
          requestFileChunk: object
        })
      });
    }).catch((err)=> navigate("/extraction", { state: { error: err.res.response.data[0].errorDescription } }));
  }

  //téléchargement avec url
  const downloadArchive = (documentProviderId: string, fileChunk: number, url: string): void => {

    if (sessionStorage.getItem('authtype') == "externe" ) {
      const petdUpdateRequestData = {
        requestLabel: readDetailRequest?.requestLabel,
        requestComment: readDetailRequest?.requestComment,
        requestStatus: readDetailRequest?.requestStatus,
        requestUserId: 'batch', 
        requestExtract: {
          documentProviderId: documentProviderId,
          chunkNumber: fileChunk
        }
      };
  
      try {
        const response = apiClient().petd.postUpdateRequest(
          sessionStorage.getItem("currentRequest"), petdUpdateRequestData
        );
      } catch (err) {
        console.log(err);
      }
    }

    // create <a> tag dynamically
    let fileLink = document.createElement('a');
    fileLink.href = url;

    // triggers the click event
    fileLink.click();
  }

  //file link timer
  const timout = ms => new Promise((r) => setTimeout(r, ms));

  useEffect(() => {
  }, [readArchiveResults])

  useEffect(() => {
    
    sendReadRequestArchive();
  }, [])

  return (
    <React.Fragment>
      <Header pageTitle={CONTENT.header.req_extraction + requestID} />
      <div className="container-fluid table-container w-50 mt-5 pt-5">
        <Table className="table table-bordered table-hover">
          {/* Table extractions columns */}
          <thead>
            <tr>
              <th className="text-center">{CONTENT.table_extraction.name}</th>
              <th className="text-center">{CONTENT.table_extraction.batch}</th>
              <th className="text-center">{CONTENT.table_extraction.download_request}</th>
              <th className="text-center">{CONTENT.table_extraction.size}</th>
              <th className="text-center"> {CONTENT.table_extraction.download}</th>
            </tr>
          </thead>

          {/* Display data in the rigth side*/}
          <tbody>
            {
              readArchiveResults?.requestFileChunk?.map((ext: RequestFileChunk) => { console.log(ext.lastDownload);
                return (
                  <tr className={ ext.lastDownload ? "table-secondary" : "table-white" }>
                    <td>{ext.documentProviderId}</td>
                    <td className="text-end">{ext.fileChunk}</td>
                    <td className=" w-25 p-0 text-center align-middle">
                      <ButtonComp
                        btnLabel="Demander le lien"
                        variant="outline-primary"
                        size="lg"
                        btnFunc={() => downloadArchiveRequest(ext.documentProviderId, ext.fileChunk)}
                      />
                    </td>
                    {ext.urls?.length ?
                      <><td className="text-end">
                        {ext.urls.map((fileUrl: Urls) => {
                          return (<><p>{fileUrl.fileSize}Mo</p></>)
                        })
                        }
                      </td>
                        <td className=" w-25 p-0 text-center align-middle">
                          {ext.urls.map((fileUrl: Urls) => {
                            return (<><p><ButtonComp
                              btnIcon="download-icon"
                              variant="outline-primary"
                              size="lg"
                              btnFunc={() => downloadArchive(ext.documentProviderId, ext.fileChunk, fileUrl.url)}
                            /></p></>)
                          })
                          }

                        </td>
                      </> : null}
                  </tr>
                );
              })
            }
          </tbody>
        </Table>
      </div>
      <ButtonList
        slotA={
          <ButtonComp
            btnLabel={CONTENT.buttons.previous}
            variant="link"
            size="lg"
            btnFunc={prevPage}
          />
        }
      />
    </React.Fragment>
  );
});


