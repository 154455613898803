//Regex to block to write"script" in the input
const htmlInjection = /(script)/gm;

/**
 * Method check input value against html injection
 *
 * @param value
 * @returns
 */
export const inputCheck = (value?: string) => {
  if (value !== undefined && htmlInjection.test(value)) {
    return false;
  }
  if (value !== undefined && value === "") {
    return false;
  }
  return true;
};

/**
 * Method check file input in order to restrict all extensions other than .csv
 *
 * @param file
 * @returns
 */
export const fileInputCheck = (file?: File) => {
  const fileName = file?.name.toLowerCase();

  if (!fileName?.endsWith('.csv') || file === undefined) {
    return false;
  }

  return true;
};
