import LoginButton from 'components/buttons/loginButton/LoginButton';
import AuthorizationService from 'api/AuthorizationService';
import {
  StyledAccessBox,
  StyledAccessBoxMainTitle,
  StyledBackground,
  StyledHighlightBoxCornerCircles,
  StyledHighlightBoxText,
  StyledMainHighlightBox,
  StyledMainSidePanel,
} from './style';

import * as messages from './messages';

const Login: React.FC = () => {

  const authenticatedUser = sessionStorage.getItem('authenticatedUser');
  const handleBackButtonPress = () => {
    if (!authenticatedUser || authenticatedUser == null) {
      window.location.replace("/login");
    }
  };

  window.addEventListener('popstate', handleBackButtonPress);

  return (
    <>
      <StyledBackground>
        <StyledMainSidePanel>
          <div style={{ display: 'flex', justifyContent: 'center', flex: '1' }}>
            <StyledMainHighlightBox>
              <StyledHighlightBoxCornerCircles
                style={{ top: '-4.3rem', left: '-5.8rem' }}
              />
              <StyledHighlightBoxText>
                {messages.HIGHLIGHT_BOX_DESCRIPTION}
              </StyledHighlightBoxText>
              <StyledHighlightBoxCornerCircles
                style={{ bottom: '-5.2rem', right: '-4.3rem' }}
              />
            </StyledMainHighlightBox>

            <StyledAccessBox>
              <StyledAccessBoxMainTitle>
                {messages.ACCESS_BOX_MAIN_TITLE}
              </StyledAccessBoxMainTitle>
              <LoginButton />
            </StyledAccessBox>
          </div>
        </StyledMainSidePanel>
      </StyledBackground>
    </>
  );
};

export default Login;
