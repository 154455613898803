import { ConfigValues } from 'types';
import { useEffect, useState } from 'react';
import Login from 'containers/login';
import Authorize from 'components/pages/authorization/Authorize';
import Callback from 'components/pages/authorization/Callback';
import SilentRefresh from 'components/pages/authorization/SilentRefresh';
import { RequestList } from "components/pages/RequestList";
import { RequestCreation } from "components/pages/RequestCreation";
import { RequestPending } from "components/pages/RequestPending";
import { ExtractionBatches } from "components/pages/ExtractionBatches";
import getEnvVariables from 'api/envService';
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import { AppProvider } from "store/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

declare global {
  interface WindowWithEnv extends Window {
    _env_: IAppConfig;
  }
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    export interface IntrinsicElements {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'pf-mediator': any; // eslint-disable @typescript-eslint/no-explicit-any
    }
  }
}

const ProtectedRoute = () => {
  // When the IAM is down you can set this flag to true to bypass the authentication process
  let bypassFlag = false;

  if (!bypassFlag) {
    if (sessionStorage.getItem('isUserAuthenticated') !== 'true') {
      return <Navigate to='/' replace />
    }
  }

  return <Outlet />;
};

const ProtectedRouteInterne = () => {
  // When the IAM is down you can set this flag to true to bypass the authentication process
  let bypassFlag = false;

  if (!bypassFlag) {
    if (sessionStorage.getItem('isUserAuthenticated') !== 'true') {
      return <Navigate to='/' replace/>
    }
    if (sessionStorage.getItem('authtype') !== "interne") {
      return <Navigate to='/home' replace />;
    }
  }
  return <Outlet />;
};

const App: React.FunctionComponent<Record<string, never>> = () => {
  const [envVariablesLoading, setEnvVariablesLoading] = useState<boolean>(true);
  const [errorEnvVariables, setErrorEnvVariables] = useState<string>('');

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const configs: ConfigValues = await getEnvVariables();
        sessionStorage.setItem('envVariables', JSON.stringify(configs));
        setErrorEnvVariables('');
      } catch (error) {
        setErrorEnvVariables(`${error}`);
      } finally {
        setEnvVariablesLoading(false);
      }
    };
    fetchConfig();
  }, []);

  const render = () => {
    if (envVariablesLoading) {
      return <p>Loading env variables...</p>;
    }
    if (errorEnvVariables) {
      return <p>{errorEnvVariables}</p>;
    }
    return (
      <AppProvider>
        <Router>
          <Routes>
          
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/authorize' element={<Authorize />} />
            <Route path='/callback' element={<Callback />} />
            <Route
              element={
                <ProtectedRoute/>
              }>
                <Route path="/list" element={<RequestList />} />
                <Route path="/home" element={<RequestList />} />
                <Route path="/reqPending" element={<RequestPending />} />
                <Route path="/extraction" element={<ExtractionBatches />} />
                <Route path='/silent-refresh' element={<SilentRefresh />} />
            </Route>
           <Route
              element={
                <ProtectedRouteInterne/>
              }>
                <Route path="/create" element={<RequestCreation />} />
              </Route>
          </Routes>
        </Router>
      </AppProvider>
    );
  };
  return <>{render()}</>;
};

export interface IAppConfig {
  baseUrlApim: string;
  apimApiKey: string;
  useMock: string;
}

export default App;
