import React from "react";
//Bootstrap component call
import Button from "react-bootstrap/Button";
//Interfaces call
import { ButtonProps } from "interfaces/button";

/**
 * Buttton component to be call when in need
 *
 * @param {string} btnLabel text to display in the button
 * @param {string} btnState use to disale the button
 * @param {string} variant kind of button wanted based on bootstrap class
 * @param {string} size size of button wanted based on bootstrap class
 * @param {string} btnIcon class passed as argument when a specific icon is wanted into the button
 * @param {function} btnFunc function passed as argument to apply the wanted logic on the button
 * @returns {React.ReactElement} a button with a logic and either a text or an icon
 */
export const ButtonComp: React.FC<ButtonProps> = React.memo(
  ({
    btnLabel = "",
    variant,
    size,
    btnIcon = "",
    btnFunc = undefined,
    btnState = false,
  }) => {
    ButtonComp.displayName = "ButtonComp";
    //Case of button displaying an icon
    if (btnIcon && btnIcon !== "") {
      return (
        <React.Fragment>
          <Button
            variant={variant}
            size={size}
            onClick={btnFunc}
            className={btnIcon}
          />
        </React.Fragment>
      );
    }
    //Case of button displaying a text
    else {
      return (
        <React.Fragment>
          <Button
            variant={variant}
            size={size}
            onClick={btnFunc}
            disabled={btnState}
          >
            {btnLabel}
          </Button>
        </React.Fragment>
      );
    }
  }
);
